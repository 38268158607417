import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import ConsoleLog   from './ConsoleLog';
import { DataGridPro,GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExportContainer,  GridToolbarExport  } from '@mui/x-data-grid-pro';
import { Box, CircularProgress, Select,MenuItem, Button, TextField, Tooltip } from '@mui/material';
import GlobalContext from './GlobalContext';

const Dashboard = ({dashboardData, role, distinctBrands, filteredCategories, selectedBrand, selectedCategory, handleBrandChange, handleCategoryChange, isLoading, selectChannelNode}) => {
    const {year, API_URL, roleLevels, goalStatus, displayCurrency, displayPct } = useContext(GlobalContext);
    
    //const dashboardDataRef = useRef(null);
    //const [filteredData, setFilteredData] = useState(null);
   
    //const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [growthPctCallout, setGrowthPctCallout] = useState(0); 
    //const [role, setRole] = useState("");

 

    
    

    const applyGrowthCallout = (value) =>{
       setGrowthPctCallout(value);
       createColumns(value);
    };

    

    const handleCellClick =(params) =>{
        selectChannelNode(params.value);
    };

    function renderIndent(treeNodeLevel, text) {
        let spc = 15 * treeNodeLevel;  
        return (
            <span style={{ paddingLeft: `${spc}px` }}>
                {text}
            </span>
        );
    }
    

    const createColumns= (growthIndicator) => {
        console.log("role",role);
        const cols = [
            //     { field: 'category', headerName: 'Category',hideable:false, editable:false ,  sortable: false ,width: "170"},
            { field: 'treeNodeName' , headerName: 'Channel', width: "360", sortable: false,
                renderCell: (params) => (
                    <div
                      style={{ cursor: 'pointer', color: 'blue' }}
                      onClick={() => handleCellClick(params)}
                    >
                      {renderIndent(params.row.treeNodeLevel,params.value)}
                    </div>
                  )
            },
            {
                field: 'status',
                headerName: 'Status',
                sortable: true,
                renderCell: (params) => (
                  <Tooltip title={`LK1: Level 1, LK2: Level 2, LK3: Level 3, FIN: Finalized`} arrow>
                    <span>{params.row.salesGoalID ? params.value : ""}</span>
                  </Tooltip>
    ),
            },
            { field: 'priorActuals_3Y', headerName: `${year-3} Actuals`, sortable: false,
                    valueGetter: (value, row) => displayCurrency(row.priorActuals_3Y), 
                    hideable:true, editable:false, width: "120"},
                { field: 'priorActuals_2Y', headerName: `${year-2} Actuals`, sortable: false,
                    valueGetter: (value, row) => displayCurrency(row.priorActuals_2Y), 
                    hideable:true, editable:false , width: "120"},
                { field: 'priorActuals_2Y_Growth_PCT', headerName: 'Growth', sortable: false,
                  valueGetter: (value, row) => displayPct(row.priorActuals_2Y_Growth_PCT), 
                  hideable:true, editable:false , width: "85"},
                { field: 'priorActuals', headerName:  `${year-1} Actuals YTD`, sortable: false,
                    valueGetter: (value, row) => displayCurrency(row.priorActuals), 
                    hideable:true, editable:false ,  width: "120"},
                { field: 'priorProjected', headerName: `${year-1} Projected`, sortable: false,
                    valueGetter: (value, row) => displayCurrency(row.priorProjected), 
                    hideable:true, editable:false ,  width: "120"},
                { field: 'priorActuals_Growth_PCT', headerName: 'Growth', sortable: false,
                    valueGetter: (value, row) => displayPct(row.priorActuals_Growth_PCT), 
                    hideable:true, editable:false , width: "85"},
                { field: 'repGoalAmt', headerName: 'Rep Agency',  sortable: false,
                    valueGetter: (value, row) => displayCurrency(row.repGoalAmt),
                    hideable:true, editable:false, width: "120" },
                { field: 'rep_Growth_PCT', headerName: 'Growth', sortable: false,
                    valueGetter: (value, row) => displayPct(row.rep_Growth_PCT),
                    hideable:true,  editable:false , width: "85",
                    renderCell: (params) => (
                        <>
                            {
                                growthIndicator !== "" && parseInt(params.value) < growthIndicator 
                                ? <span className='highlightRed'>{params.value}</span>
                                : <span className={params.value < 0 ? "negativeText" : ""}>{params.value}</span>
                            }
                        </>    
                        
                      )
                },
                ...(role !== roleLevels.Level1 ? 
                    [
                { field: 'rsdGoalAmt', headerName: 'RSD',  sortable: false,
                    valueGetter: (value, row) => displayCurrency(row.rsdGoalAmt),
                    hideable:true, editable:false, width: "120"},
                { field: 'rsD_Growth_PCT', headerName: 'Growth', sortable: false,
                    valueGetter: (value, row) => displayPct(row.rsD_Growth_PCT),
                    hideable:true,  editable:false, width: "90",
                    renderCell: (params) => (
                        <>
                            {
                                growthIndicator !== "" && parseInt(params.value) < growthIndicator 
                                ? <span className='highlightRed'>{params.value}</span>
                                : <span className={params.value < 0 ? "negativeText" : ""}>{params.value}</span>
                            }
                        </>    
                        
                      )
                }] : []) ,
                
                ...(role === roleLevels.Level3 ? 
                    [
                        {field: 'finalGoalAmt', headerName: 'Final', sortable: false,
                          valueGetter: (value, row) => displayCurrency(row.finalGoalAmt),
                          hideable:true,  editable:false, width: "120"},
                        {field: 'final_Growth_PCT', headerName: 'Growth', sortable: false,
                          valueGetter: (value, row) => displayPct(row.final_Growth_PCT),
                          hideable:true,  editable: false,  width: "85",
                          renderCell: (params) => (
                            <>
                                {
                                    growthIndicator !== "" && parseInt(params.value) < growthIndicator 
                                    ? <span className='highlightRed'>{params.value}</span>
                                    : <span className={params.value < 0 ? "negativeText" : ""}>{params.value}</span>
                                }
                            </>    
                            
                          )
                        
                        },
                          
                          { field: 'planAmt', headerName: 'Plan', sortable: false,
                            valueGetter: (value, row) => row.planAmt === 0 ? "" : displayCurrency(row.planAmt),
                            hideable:true, editable:false, width: "120"}
                    ]
                    :[]),
        ];
        setColumns(cols);
    };

     useEffect(()=> {
           createColumns(growthPctCallout);
            
     },[dashboardData]);


    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton 
             slotProps={{ tooltip: { title: 'Channel Filter' },  }}
            />
            <GridToolbarExport />
            <Box sx={{ flexGrow: 1 }} />
             
          </GridToolbarContainer>
        ); 
      }


    return (
        <div className="tables">
            {
                        isLoading && (
                          <CircularProgress size="30px" /> )
            }
         {
            distinctBrands && (
            <div className='manageGoals'>
                <div className='filters'>
                     
                    {distinctBrands && (
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedBrand}
                            label="Brand"
                            onChange={(e) => 
                                handleBrandChange(e.target.value)}
                            >
                            <MenuItem value="ALL BRANDS" selected>All BRANDS</MenuItem>  
                            { distinctBrands && distinctBrands.map((d,key) => <MenuItem key={key} value={d}>{d}</MenuItem>)}

                        </Select>

                    )}
                   {filteredCategories && (
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCategory}
                        label="Category"
                        onChange={(e) => handleCategoryChange(e.target.value)}
                    >
                        <MenuItem value="ALL CATEGORIES" selected>ALL CATEGORIES</MenuItem>
                        { filteredCategories && filteredCategories.map((c,key) => <MenuItem key={key} value={c}>{c}</MenuItem>)}
    
                    </Select>

                   )
                   
                   }

                    
                    <Box>
                         <TextField id="outlined-basic" label="Growth Indicator" variant="outlined" 
                         onBlur={(e) => applyGrowthCallout(e.target.value)} />
                    </Box>
                    
                </div>
            </div>
            )
         }
            <div className="goalsTable">
                {dashboardData && columns && (
                <DataGridPro
                    columns={columns}
                    pinnedColumns={columns}
                    componentsProps={{
                        columnHeaders: {
                            sx: {
                                fontWeight: 'bold',
                            },
                        },
                    }}
                    
                    loading={dashboardData.length === 0}
                    slots={{ toolbar: CustomToolbar }}
                    rows={dashboardData.map((row, index) => ({
                    ...row,
                    id: row.treeNodeOrdinal || index, // Ensure unique IDs
                    }))}
                />
                )}
            </div>
        </div>

    );


}

export default Dashboard;