import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from './GlobalContext';
import { DataGridPro } from '@mui/x-data-grid-pro';
import ProgressBar from './ProgressBar';
import { Box,  CircularProgress, Typography, Select,MenuItem, Button  } from '@mui/material';
import ConsoleLog   from './ConsoleLog';
import LockDialog from './LockDialog';
import NoteDialog from './NoteDialog';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faEnvelope, faCheck, faTableCellsRowUnlock } from '@fortawesome/free-solid-svg-icons'


const GoalsDataGrid = ({ token,isLoading,  goalsDetails, treeNode, salesGoalID, refresh, refreshTree }) => {
    const {year, API_URL, roleLevels, goalStatus, displayCurrency, displayPct } = useContext(GlobalContext);

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [distinctBrands, setDistinctBrands] = useState(null);
    const [distinctCategories, setDistinctCategories] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState("ALL BRANDS");
    const [selectedCategory, setSelectedCategory] = useState("ALL CATEGORIES");
   
    const [editView, setEditView] = useState(false);
     
    const [status, setStatus] = useState("");
    const [role, setRole] = useState("");
    const [columns, setColumns] = useState ([]);

    const [progress, setProgress] = useState(null);
    

    useEffect(() => {
        
        var r = goalsDetails[0]?.roleName;
        var s = goalsDetails[1]?.status;
        debugger;
        setStatus(s);
        setRole(r);

        setData(goalsDetails);
        setFilteredData(goalsDetails);

        setEditView(salesGoalID.length !== 0 && ((s === goalStatus.Level1 && r=== roleLevels.Level1) || (s === goalStatus.Level2 && r === roleLevels.Level2) || (s === goalStatus.Level3 && r === roleLevels.Level3)));
        setDistinctBrands(getUniqueBrands(goalsDetails));
        setDistinctCategories(getUniqueCategories(goalsDetails));
       
        const cols = [
            { field: 'brand', headerName: 'Brand',  hideable:false, editable:false, sortable: false , width: "120"},
            { field: 'category', headerName: 'Category',hideable:false, editable:false ,  sortable: false ,width: "170"},
            { field: 'priorActuals_3Y', headerName:  `${year-3} Actuals`, type: "number",
                valueGetter: (value, row) => displayCurrency(row.priorActuals_3Y), 
                hideable:true, editable:false, width: "120",sortable: true,
                sortComparator: (v1, v2) => {
                    return  parseInt(v1.replace(/,/g, '')) - parseInt(v2.replace(/,/g, ''))
                } ,
            },

            { field: 'priorActuals_2Y', headerName: `${year-2} Actuals`, type: "number",
                valueGetter: (value, row) => displayCurrency(row.priorActuals_2Y), 
                hideable:true, editable:false , width: "120",
                sortable: true,
                sortComparator: (v1, v2) => {
                    return  parseInt(v1.replace(/,/g, '')) - parseInt(v2.replace(/,/g, ''))
                } 
            }
            ,
            { field: 'priorActuals_2Y_Growth_PCT', headerName: 'Growth', 
              valueGetter: (value, row) => displayPct(row.priorActuals_2Y_Growth_PCT), 
              hideable:true, editable:false , width: "85",sortable: true,
              sortComparator: (v1, v2) => {
                  return  parseInt(v1.replace(/%/g, '')) - parseInt(v2.replace(/%/g, ''))
              } 
            
            },
            { field: 'priorActuals', headerName: `${year-1} Actuals YTD` , type: "number",
                valueGetter: (value, row) => displayCurrency(row.priorActuals), 
                hideable:true, editable:false ,  width: "120"},
            { field: 'priorProjected', headerName: `${year-1} Projected`,type: "number",
                valueGetter: (value, row) => displayCurrency(row.priorProjected), 
                hideable:true, editable:false ,  width: "120",
                sortable: true,
                sortComparator: (v1, v2) => {
                    return  parseInt(v1.replace(/,/g, '')) - parseInt(v2.replace(/,/g, ''))
                } 
            },
            { field: 'priorActuals_Growth_PCT', headerName: 'Growth', 
                valueGetter: (value, row) => displayPct(row.priorActuals_Growth_PCT), 
                hideable:true, editable:false , width: "85",sortable: true,
                sortComparator: (v1, v2) => {
                    return  parseInt(v1.replace(/%/g, '')) - parseInt(v2.replace(/%/g, ''))
                } 
            },
            { field: 'repGoalAmt', headerName: 'Rep Agency',  type: "number",
                valueGetter: (value, row) => displayCurrency(row.repGoalAmt),
                hideable:true, editable:salesGoalID.length > 0 && r === roleLevels.Level1 && s === goalStatus.Level1, width: "120",
                sortable: true,
                sortComparator: (v1, v2) => {
                    return  parseInt(v1.replace(/,/g, '')) - parseInt(v2.replace(/,/g, ''))
                } ,
                headerClassName: r === roleLevels.Level1 && s === goalStatus.Level1 ? "highlightYellow" :""
            },
            { field: 'rep_Growth_PCT', headerName: 'Growth', 
                valueGetter: (value, row) => displayPct(row.rep_Growth_PCT),
                hideable:true,  editable:salesGoalID.length > 0 && r === roleLevels.Level1 && s === goalStatus.Level1 , width: "85",
                sortable: true,
              sortComparator: (v1, v2) => {
                  return  parseInt(v1.replace(/%/g, '')) - parseInt(v2.replace(/%/g, ''))
              } ,
                headerClassName: r === roleLevels.Level1 && s === goalStatus.Level1 ? "highlightYellow" :""
            },

            ...(r !== roleLevels.Level1 ? 
                [ 
                    {field: 'rsdGoalAmt', headerName: 'RSD', type: "number",
                      valueGetter: (value, row) => displayCurrency(row.rsdGoalAmt),
                      hideable:true, editable:salesGoalID.length > 0 && r === roleLevels.Level2 && s === goalStatus.Level2, width: "120",
                      sortable: true,
                      sortComparator: (v1, v2) => {
                        return  parseInt(v1.replace(/,/g, '')) - parseInt(v2.replace(/,/g, ''))
                        },
                      headerClassName: r === roleLevels.Level2 && s === goalStatus.Level2 ? "highlightYellow" :""
                    },
                    {field: 'rsD_Growth_PCT', headerName: 'Growth', 
                      valueGetter: (value, row) => displayPct(row.rsD_Growth_PCT),
                      hideable:true,  editable:salesGoalID.length > 0 && r === roleLevels.Level2 && s === goalStatus.Level2, width: "90",
                      sortable: true,
                      sortComparator: (v1, v2) => {
                          return  parseInt(v1.replace(/%/g, '')) - parseInt(v2.replace(/%/g, ''))
                      } ,
                      headerClassName: r === roleLevels.Level2 && s === goalStatus.Level2 ? "highlightYellow" :""
                    } ,
                ]
                :[]
    
            ),
            ...(r === roleLevels.Level3 ?  
                [
                    {field: 'finalGoalAmt', headerName: 'Final', type: "number",
                      valueGetter: (value, row) => displayCurrency(row.finalGoalAmt),
                      hideable:true,  editable:salesGoalID.length > 0 && r === roleLevels.Level3 && s === goalStatus.Level3, width: "120",
                      sortable: true,
                      sortComparator: (v1, v2) => {
                        return  parseInt(v1.replace(/,/g, '')) - parseInt(v2.replace(/,/g, ''))
                        },
                        headerClassName: r === roleLevels.Level3 && s === goalStatus.Level3 ? "highlightYellow" :""
                    },
                    {field: 'final_Growth_PCT', headerName: 'Growth', 
                      valueGetter: (value, row) => displayPct(row.final_Growth_PCT),
                      hideable:true,  editable:salesGoalID.length > 0 && r === roleLevels.Level3 && s === goalStatus.Level3,  width: "85",
                      sortable: true,
                      sortComparator: (v1, v2) => {
                          return  parseInt(v1.replace(/%/g, '')) - parseInt(v2.replace(/%/g, ''))
                      } ,
                      headerClassName: r === roleLevels.Level3 && s === goalStatus.Level3 ? "highlightYellow" :""
                    },
                      { field: 'planAmt', headerName: 'Plan', type: "number",
                        valueGetter: (value, row) => displayCurrency(row.planAmt),
                        hideable:true, editable:false, width: "120",
                        sortable: true,
                        sortComparator: (v1, v2) => {
                          return  parseInt(v1.replace(/,/g, '')) - parseInt(v2.replace(/,/g, ''))
                          }
                    }
                ]
                :[]),

                ...(((s === "LK1" && r === roleLevels.Level1) || (s==="LK2" && r === roleLevels.Level2) || s ==="LK3" && r=== roleLevels.Level3) ? 
                    [
                { 
                    field:'updated', headerName: 'Updated', 
                    renderCell: (params) => (
    
                        <div>
                            {params.value === true && (
                                <FontAwesomeIcon icon={faCheck} />
                            )}
                           
                        </div>
        
                        
                      )  
                }] : [])
          
        ];
 
        setColumns(cols);

        const total = goalsDetails.filter((sg) => sg.brand.trim() !== "" && sg.brand.trim() !== "ALL BRANDS" && sg.brand.trim() !== "SUB-TOTAL").length;
        const updatedCount = goalsDetails.filter(goal => goal.updated === true).length;
        setProgress(Math.round((updatedCount/total)*100));
        
    }, [goalsDetails,treeNode,salesGoalID]);


    const getUniqueBrands = (data) => {
        return data.reduce((uniqueBrands, item) => {
            if (!uniqueBrands.includes(item.brand) && item.brand.trim() !== "" && item.brand.trim() !=="SUB-TOTAL") {
                uniqueBrands.push(item.brand);
            }
            return uniqueBrands;
        }, []);
    };

    const getUniqueCategories = (data) => {
        return data.reduce((uniqueCats, item) => {
            if (!uniqueCats.includes(item.category) && item.category.trim() !== "" && item.category.trim() !== "GRAND TOTAL" && item.brand.trim() !=="SUB-TOTAL" ) {
                uniqueCats.push(item.category);
            }
            return uniqueCats;
        }, []);
    }

    const filterBrand = (newValue) =>
    {
        setSelectedBrand(newValue);
        setSelectedCategory("ALL CATEGORIES");
        if(newValue === "ALL BRANDS")
            return data;
        else{
            var fData = data.filter((g) => g.brand === newValue  || g.category === newValue)
            setDistinctCategories(getUniqueCategories(fData));
            return fData;
        }

    }

    const filterCategory = (newValue) =>
    {
        let fData = filterBrand(selectedBrand);
        setSelectedCategory(newValue);
   
        if(newValue === "ALL CATEGORIES" && selectedBrand === "ALL BRANDS")
            return data;
        else if(newValue === "ALL CATEGORIES")
        {
            return fData;
        }
        else{
            return fData.filter((g) => g.category === newValue );
        }
            
    }
        
    const handleLockClick = () =>
    {
        if((progress === 100 && role === roleLevels.Level1) || (role !== roleLevels.Level1))
        {
            const request = {
                token: token,
                salesGoalID: salesGoalID,
                status: getNextStatus(status)
            }
            axios.post(`${API_URL}/salesgoals/status`, request,
                {  
                    headers: { 'Content-Type': 'application/json' }
                })
                .then((response) =>  {
                    refresh();
                    refreshTree(treeNode);
                    toast.success("All Sales Goals have been submitted.");
                })
                .catch(err => 
                    console.error(err)
                );
        }
       else{
        toast.warning("Please submit a sales goal for each brand and category listed below.");
       }
    }

    const getNextStatus = (currStatus) => {
        let nextStatus = "";
        switch(currStatus){
            case goalStatus.Level1:
                nextStatus = goalStatus.Level2;
                break;
            case goalStatus.Level2:
                nextStatus = goalStatus.Level3;
                break;
            case goalStatus.level3:
                nextStatus = goalStatus.Level4;
                break;
            default:
                nextStatus = goalStatus.Level4;
        }
        return nextStatus;
    }

    const getBackStatus = (currStatus) => {
        debugger;
        let backStatus = "";
        switch(currStatus){
            case goalStatus.Level4:
                backStatus = goalStatus.Level3;
                break;
            case goalStatus.Level3:
                backStatus = goalStatus.Level2;
                break;
            case goalStatus.Level2:
                backStatus = goalStatus.Level1;
                break;
            default:
                backStatus = goalStatus.Level4;
        }
        return backStatus;
    }

    const handleUnlockClick = () =>
    {
        const request = {
            token: token,
            salesGoalID: salesGoalID,
            status: getBackStatus(status)
        }
        axios.post(`${API_URL}/salesgoals/status`, request,
            {  
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) =>  {
                refresh();
                    refreshTree(treeNode);
                toast.success("Sales Goal has been unlocked.");
            })
            .catch(err => 
                console.error(err)
            );
    }

    const handleSaveNoteClick = (note) =>{
        var newLevel = getBackStatus(status);
        debugger;
        if(newLevel !== "")
        {
            const noteRequest = {
                salesGoalID: salesGoalID,
                note: note,
                token: token,
                newLevel: newLevel
            };

            axios.post(`${API_URL}/salesgoals/note`, noteRequest,
                {  
                    headers: { 'Content-Type': 'application/json' }
                })
                .then((response) =>  {
                    refresh();
                    refreshTree(treeNode);
                })
                .catch(err => 
                    console.error(err)
                );
        }
        

    }

    const handleExportClick =  async () => {
        try{
            const request = {
                token: token,
                treeNode: treeNode.name,
                treeNodeDesc: treeNode.description
              };
            const response = await axios.post(`${API_URL}/salesgoals/export`, request, {
                  responseType: 'blob',
                  headers: { 'Content-Type': 'application/json'
               } 
            });
            const blob = new Blob([response.data]);
            const a = document.createElement('a');
            const downloadUrl = window.URL.createObjectURL(blob);
            a.href = downloadUrl;
    
            // Generate the filename
            const filename = `${treeNode.description}_SalesGoals_${new Date().toJSON().slice(0, 10)}.xlsx`;
            a.download = filename;
    
            // Trigger the download
            a.click();
    
            // Revoke the object URL after the download to free memory
            window.URL.revokeObjectURL(downloadUrl);
        }
        catch (error) {
            console.error('Error downloading the file:', error);
      }  


       
    }

    const updateTotals = (newAmount, goalRow) =>{
        //make call to back-end to update the sales goal record (incl. total lines)
        var salesGoalRequest = {
            token: token,
            salesGoalID: goalRow.salesGoalID,
            brand: goalRow.brand,
            category: goalRow.category,
            amount: newAmount
        };
 
        
        axios.post(`${API_URL}/salesgoals/update`, salesGoalRequest,
            {  
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) =>  {
                console.log("Refreshing");
                refresh();
                filterBrand(selectedBrand);
            })
            .catch(err => 
                console.error(err)
            );
    }

    function isValidAmount(amt)
    {
        let num = Number(amt);
        // Check if the input is a number and satisfies the condition (0 or > 999)
        if (!isNaN(num) && (num === 0 || num > 999)) {
            return true;
        } else {
            return false;
        }
    };


    const handleProcessRowUpdate = (newRow, oldRow) => {
    
       let newAmount = 0;
       let isValid = true;
       debugger;
       if (newRow.repGoalAmt !== oldRow.repGoalAmt){
            let sanitizedValue = newRow.repGoalAmt.toString().replace(/,/g, '');
            newRow.repGoalAmt = parseFloat(sanitizedValue);
            isValid = (isValidAmount(newRow.repGoalAmt));
            if(isValid)
            {
                newAmount =   Math.round(newRow.repGoalAmt / 1000) * 1000;
                newRow.rep_Growth_PCT =  Math.round(((newAmount- newRow.priorProjected)/newRow.priorProjected) * 100);
                newRow.repGoalAmt = newAmount;
            }
                
       }
       else if(newRow.rep_Growth_PCT !== oldRow.rep_Growth_PCT) {
            let sanitizedValue = newRow.rep_Growth_PCT.replace(/%/g, '');
            newRow.rep_Growth_PCT = parseFloat(sanitizedValue);        
            let currentYrProjection = parseFloat(newRow.priorProjected);
            let nextYearGrowthPct = parseFloat(newRow.rep_Growth_PCT);
            newRow.repGoalAmt = Math.round((currentYrProjection * (1 + nextYearGrowthPct / 100)/100))*100;
            newAmount = Math.round(newRow.repGoalAmt / 1000) * 1000;
            isValid= newAmount !== 0 &&  newAmount > 999;
         
       }

       if (newRow.rsdGoalAmt !== oldRow.rsdGoalAmt){
            let sanitizedValue = newRow.rsdGoalAmt.toString().replace(/,/g, '');
            newRow.rsdGoalAmt = parseFloat(sanitizedValue);        
            isValid = (isValidAmount(newRow.rsdGoalAmt));
            if(isValid){
                newAmount = Math.round(newRow.rsdGoalAmt / 1000) * 1000;  
                newRow.rsD_Growth_PCT =  Math.round(((newAmount - newRow.priorProjected)/newRow.priorProjected) * 100);
                newRow.rsdGoalAmt = newAmount;
            }
             
       }
       else if(newRow.rsD_Growth_PCT !== oldRow.rsD_Growth_PCT) {
            let sanitizedValue = newRow.rsD_Growth_PCT.replace(/%/g, '');
            newRow.rsD_Growth_PCT = parseFloat(sanitizedValue);     
            let currentYrProjection = parseFloat(newRow.priorProjected);
            let nextYearGrowthPct = parseFloat(newRow.rsD_Growth_PCT);
            newRow.rsdGoalAmt = Math.round((currentYrProjection * (1 + nextYearGrowthPct / 100)/100))*100;
            newAmount =   newRow.rsdGoalAmt 
            isValid= isValidAmount(newAmount);
       }

       if (newRow.finalGoalAmt !== oldRow.finalGoalAmt){
         
            let sanitizedValue = newRow.finalGoalAmt.toString().replace(/,/g, '');
            newRow.finalGoalAmt = parseFloat(sanitizedValue);        
            isValid = (isValidAmount(newRow.finalGoalAmt));
            if(isValid){
                newAmount = Math.round(newRow.finalGoalAmt / 1000) * 1000; 
                newRow.final_Growth_PCT =  Math.round(((newAmount - newRow.priorProjected)/newRow.priorProjected) * 100);
                newRow.finalGoalAmt = newAmount;
            }
           
    
        }
        else if(newRow.final_Growth_PCT !== oldRow.final_Growth_PCT) {
            let sanitizedValue = newRow.final_Growth_PCT.replace(/%/g, '');
            newRow.final_Growth_PCT = parseFloat(sanitizedValue);    
            const currentYrProjection = parseFloat(newRow.priorProjected);
            const nextYearGrowthPct = parseFloat(newRow.final_Growth_PCT);
            newRow.finalGoalAmt = Math.round((currentYrProjection * (1 + nextYearGrowthPct / 100)/100))*100;
            newAmount = newRow.finalGoalAmt;
            isValid = isValidAmount(newAmount);
        }
        
       if(isValid){
            updateTotals(newAmount, newRow);
            toast.success("Goal Amount was saved.");
            return newRow;
       }
        else
        {
            toast.error("Goal Amount must be 0 or greater than 1000.Check the Growth Percentage used.");
            return oldRow;
        }
            

    };
 

    if (!goalsDetails || goalsDetails.length === 0) {
        return (<div>Loading...</div>);
    }


    return (
        
        <div className="tables">
        {
           isLoading && (
                <CircularProgress size="30px" /> )
        }

         {
                distinctBrands && (
                <div className='manageGoals'>
                    <div className='filters'>
                        <Box>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedBrand}
                                label="Brand"
                                onChange={(e) => 
                                    setFilteredData(filterBrand(e.target.value))}
                            >
                                <MenuItem value="ALL BRANDS" selected>All BRANDS</MenuItem>
                                { distinctBrands && distinctBrands.map((d,key) => <MenuItem key={key} value={d}>{d}</MenuItem>)}
            
                            </Select>
                        </Box>
                        
                        <Box>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedCategory}
                                label="Category"
                                onChange={(e) => setFilteredData(filterCategory(e.target.value))}
                            >
                                <MenuItem value="ALL CATEGORIES" selected>All CATEGORIES</MenuItem>
                                { distinctCategories && distinctCategories.map((c,key) => <MenuItem key={key} value={c}>{c}</MenuItem>)}
            
                            </Select>
                        </Box>            
                        
                        <Box>
                        {/* <ConsoleLog   status={status} salesGoalID={salesGoalID}/>  */}
                        {
                            status === "LK1" && salesGoalID && salesGoalID.length > 0 && (
                            <ProgressBar bgcolor="#3598DB" completed={progress} />
                            )
                        }
                        
                        {/* <ConsoleLog editView={editView} status={status} role={role}/> */}

                        { editView && (
                            <div className="instructions highlightYellow">Double click into a cell to edit.<br />Values will be rounded to nearest thousand.</div>
                            )
                        }

                        </Box>
                        
                        

                                
                    </div>
                    <div className='btnCol'>
                        {
                            goalsDetails && (
                                <ButtonSection status={status} 
                                    isCompleted={progress===100}
                                    progress={progress}
                                    role={role}
                                    salesGoalID={salesGoalID}
                                    btnLockClick={handleLockClick} 
                                    btnUnLockClick={handleUnlockClick} 
                                    btnSaveNoteClick={handleSaveNoteClick}
                                    btnExportClick={handleExportClick} />
                            )
                        
                        }
                    </div>
                 
                </div>
                )
            }
            <div className='goalsTable'>
                <DataGridPro columns={columns} 
                 columnVisibilityModel={{
                    // Hide columns status and traderName, the other columns will remain visible
                     
                    updatedCount: salesGoalID.length > 0,
                  }}
                    componentsProps={{
                    columnHeaders: {
                        sx: {
                        '& .MuiDataGrid-columnHeader': {
                            fontWeight: 'bold',
                        },
                        },
                    },
                    }}
            
                    rows={filteredData.filter(r=>r.brand.trim() !== "")}  
                        getRowClassName={(params) => {
                        if(params.row.brand.trim() === "SUB-TOTAL")
                            return 'subShadedRow';
                        else return 'nothing';
                        }}
                    isCellEditable={(params) =>  params.row.brand.trim() !== "SUB-TOTAL"}
                    
                    processRowUpdate={handleProcessRowUpdate}
                    onProcessRowUpdateError={(error) => console.log(error)}
                    
                />  

            </div>
           
        </div>
    );

  

}

const ButtonSection = ({status, isCompleted,progress, role,  salesGoalID,   btnLockClick, btnUnLockClick, btnSaveNoteClick, btnExportClick}) =>{
    const { roleLevels, goalStatus} = useContext(GlobalContext);
    const [showLockDialog, setLockDialog] = useState(false);
    const [showNoteDialog, setNoteDialog] = useState(false);
    const [showBtnType, setShowBtnType] = useState("");
    const [allowNotes, setAllowNotes] = useState(false);

    const lockSalesGoal = (lockType) =>
    {
            setLockDialog(false);
            if(lockType ==="lock")
                btnLockClick();
            else
                btnUnLockClick();

                setShowBtnType("");
    }
            
    const closeLockDialog = () =>
    {
        setLockDialog(false);
    }

 

    const saveSendNote = (note) =>
    {

         if(note && note.length > 0 ) 
         { 
            setShowBtnType("");
            btnSaveNoteClick(note);
            toast.success("Note has been sent. Sales Goal has been reverted back a level.");
         }
         setNoteDialog(false);
    }

    const closeNoteDialog = () =>
    {
        setNoteDialog(false);
    }

    useEffect(() => {
        setShowBtnType("");
        setAllowNotes(false);

        if(salesGoalID === "")
        {
            setShowBtnType("");
            setAllowNotes(false);
        }
        else {
            if((status === goalStatus.Level2 && role === roleLevels.Level2) || (status === goalStatus.Level3 && role === roleLevels.Level3))
                setAllowNotes(true);
            
    
            if(status === goalStatus.Level4 && role === roleLevels.Level3)
                setShowBtnType("SetBackToLevel3");
            else if(status === goalStatus.Level3 && role === roleLevels.Level3 )
               setShowBtnType("Finalize");
            else if(status !== goalStatus.Level4 && role !== roleLevels.Level3)
            {
                
                if((status === goalStatus.Level1 && role === roleLevels.Level1 && isCompleted) || (status === goalStatus.Level2 && role === roleLevels.Level2))
                    setShowBtnType("Submit");
                else
                     setShowBtnType("");
            }
        }
        

    },[status,isCompleted])


    return(
        <React.Fragment>
                <Box>
                    <Button className="lockBtn green" onClick={btnExportClick}><FontAwesomeIcon icon={faFileExcel} title="Export to Excel" size="lg" className="addpadding"/>Export</Button>
                </Box>
                
                    {/* <ConsoleLog showBtnType={showBtnType} allowNotes={allowNotes} /> */}
                       
                    
                    {showBtnType === "SetBackToLevel3"
                        && (
                            <Button className="lockBtn" onClick={ (e) => {
                                setLockDialog(true);
                            }}>
                                <FontAwesomeIcon icon={faTableCellsRowUnlock} title="Unlock Back to Level 3" size="lg" className="addpadding"></FontAwesomeIcon>Unlock 
                            </Button>
                        )
                    }

                    {
                        allowNotes  && (
                            <Box>
                                <Button className="lockBtn" onClick={ (e) => {
                                setNoteDialog(true);
                            }   }>
                                    <FontAwesomeIcon icon={faEnvelope} title="Send Note and Push Back" size="lg" className="addpadding"/>
                                    Note
                                </Button>
                            </Box>
                            
                        )
                    }
                     

                    {
                        showBtnType === "Finalize" && (
                            <Box>
                                <Button className="lockBtn" 
                                    onClick={ (e) => {
                                    setLockDialog(true);
                                }}>
                                <FontAwesomeIcon icon={faCheck} title= "Finalize" size="lg" className="addpadding"/> Finalize
                                </Button>

                            </Box>
                            

                        )
                    }

                    {
                        showBtnType === "Submit"  && (
                            <Box>
                                <Button className="lockBtn" 
                                    onClick={ (e) => {
                                    setLockDialog(true);
                                }}>
                                    <FontAwesomeIcon icon={faCheck} title= "Submit and Pass to Next Level" size="lg" className="addpadding"/> Submit
                                </Button>
                            </Box>
                         )
                    }
                    <LockDialog open={showLockDialog} lockType={showBtnType === "SetBackToLevel3" ? "unlock" : "lock"} onConfirm={lockSalesGoal} onClose={closeLockDialog} />
                    <NoteDialog open={showNoteDialog} salesGoalID={salesGoalID} onConfirm={saveSendNote} onClose={closeNoteDialog} />
              
     
            
            
        
        </React.Fragment>
        
    );

    
}


export default GoalsDataGrid;