import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from './GlobalContext';
import axios from 'axios';
import ConsoleLog   from './ConsoleLog';
 
import GoalsDataSummaryGrid from './GoalsDataSummaryGrid';
import GoalsDataGrid from './GoalsDataGrid.js';


const Goals = ({token, goalsData, treeNode, salesGoalID, refresh, refreshTree, isLoading}) => {
    //filter out summary rows
    const [summaryData, setSummaryData] = useState(null);
    const [detailData, setDetailData] = useState(null);


    useEffect(()=>{
        console.log("if goalsData");
       
        if(goalsData){
            var sData =  goalsData.filter(g=> g.brand.trim() === "ALL BRANDS" || g.brand.trim() ==="SUB-TOTAL");
            console.log("sData", sData);
            setSummaryData(goalsData.filter(g=> g.brand.trim() === "ALL BRANDS" || g.brand.trim() ==="SUB-TOTAL"));
            setDetailData(goalsData.filter(g=> g.brand !== "ALL BRANDS" && g.brand.trim() !== "SUB-TOTAL"));  
        }
        
    },[goalsData]);

    return (
        <React.Fragment>
            {
                summaryData && (
                    <>
                        {/* <GoalsSummaryTable goalRows={summaryData} /> */}
                        <GoalsDataSummaryGrid goalRows={summaryData} />
                    </>
                )
            }

            {
                detailData && (
                    <GoalsDataGrid token={token} goalsDetails={detailData} treeNode={treeNode} salesGoalID={salesGoalID} refresh={refresh}  refreshTree={refreshTree} /> 
                )
            }

           
            
        </React.Fragment>

    );
}

export default Goals;