import React, { useState, useRef, useEffect, useContext, act } from 'react';
import {  useLocation } from 'react-router-dom';

import axios from 'axios';
import GlobalContext from './GlobalContext';
import ConsoleLog   from './ConsoleLog';
import logo from './logo.svg';

import {
   Button } from '@mui/material';
import CustomerTree from './CustomerTree';
import Charts from './Charts';
import Goals from './Goals';
import Dashboard from './Dashboard';
import {  CircularProgress } from '@mui/material';


 const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };


 const Home = () =>{
    const [viewDashboard, setViewDashboard] = useState(false);
    const {year, API_URL, roleLevels, goalStatus, displayCurrency, displayPct } = useContext(GlobalContext);
    const [isLeftNavMinimized, setIsLeftNavMinimized] = useState(window.innerWidth < 1200);
    const [activeToken, setActiveToken] = useState(null);
    const [isValidToken, setIsValidToken] = useState(false);
    const [selectedSalesGoalID, setSelectedSalesGoalID] = useState(null);
    const [selectedNode, setSelectedNode] = useState(null); // State to manage the selected node
    const [dashboardRootNode, setDashboardRootNode] = useState(null); // State to manage the selected node

    const [treeData, setTreeData] = useState(null);
    const [salesDetails, setSalesDetails] = useState(null);
    const [salesSummary, setSalesSummary] = useState(null);

    //dashboard ---------------------------------------------------------
    const [brandsCats, setBrandsCats] = useState(null);
    const [distinctBrands, setDistinctBrands] = useState(null);
    const [distinctCategories, setDistinctCategories] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState('ALL BRANDS');
    const [selectedCategory, setSelectedCategory] = useState('ALL CATEGORIES');
    const [filteredCategories, setFilteredCategories] = useState(null);
    const dashboardDataRef = useRef(null);  
    const [dashboardData, setDashboardData] = useState(null);
 
    const [role, setRole] = useState("");
    const [isLoading, setIsLoading] =useState(false);
    // -----------------------------------------------------------------

    const query = useQuery();
    
   
    
    useEffect(() => {

      const handleResize = () => {
        if (window.innerWidth >= 1200) {
          setIsLeftNavMinimized(false); // Automatically expand for larger screens
        } else {
          setIsLeftNavMinimized(true); // Auto-collapse for smaller screens
        }
      };


      const getToken = async (cn, email) => {
        try{
          const response = await axios.get(`${API_URL}/Auth?cn=${encodeURIComponent(cn)}&e=${encodeURIComponent(email)}`);
          return response.data.token;  
        }
        catch(error){
            console.error('Error validating custoner number and/or email:', error);
            return false;
        };
  
      }
  

      const cn = query.get('cn');
      const email = query.get('e');
      
      if (cn && email) {
            getToken(cn, email).then(resp => {
              let token = resp;
              setActiveToken(token);
              if(token && token !== false)
                getCustomerTree(token)
                    .then((treeResp) => {
                      setTreeData(treeResp);
                      if(treeResp.rootNode && treeResp.rootNode.children &&  treeResp.rootNode.children.length === 0)
                      {
                         
                        setViewDashboard(false);
                        setSelectedNode(treeResp.rootNode);
                        setSelectedSalesGoalID(treeResp.rootNode.salesGoalID);
                        setIsLeftNavMinimized(prevState => !prevState);
                      }
                      else{
                        setDashboardRootNode(treeResp.rootNode);
                        if (!dashboardDataRef.current && token) {
                          // Fetch data only if it hasn't been loaded
                          setIsLoading(true);
                          fetchDashboardData(token,treeResp.rootNode);
                        } else {
                          setDashboardData(dashboardDataRef.current);
                          //filterData(selectedBrand, selectedCategory);
                        }
                        setViewDashboard(true);
                      }
                      
                    })
            }).catch(error => console.error('Error fetching token', error));

        }

        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
    },[]);


    async function fetchDashboardData(token, rootNode) {
      try {
        const bcResponse = await getUniqueBrandsAndCategories(token, rootNode);
        setBrandsCats(bcResponse);
        setDistinctBrands([...new Set(bcResponse.map((item) => item.brand))]);
        
        const uniqueCats = [...new Set(bcResponse.map((item) => item.category))];
        setDistinctCategories(uniqueCats);
        setFilteredCategories(uniqueCats);
  
        const dashboardResponse = await getDashboardSummary(token, selectedBrand, selectedCategory);
        dashboardDataRef.current = dashboardResponse.sort((a, b) => b.ordinal - a.ordinal);
        setDashboardData(dashboardResponse);
        setRole(dashboardResponse[0]?.roleName);
         
         
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }

    const getDashboardSummary = async (token, brand, category) => {
        try {
            
            const request = { token, brand: brand ?? selectedBrand, category: category ?? selectedCategory };
            const response = await axios.post(`${API_URL}/salesgoals/dashboardSummary`, request);

            return response.data;
          } catch (error) {
                console.error('Error fetching dashboard summary:', error);
            return [];
          }
    };

    

    const handleDashboardBrandChange = async (newBrand) => {
      setIsLoading(true);
      
      setSelectedBrand(newBrand);
      setSelectedCategory('ALL CATEGORIES'); // Reset category when brand changes
  
      // Filter categories based on the selected brand
      const filteredCats = newBrand !== "ALL BRANDS" ? brandsCats
                            .filter((item) => item.brand === newBrand)
                            .map((item) => item.category)
                            : brandsCats
                            .map((item) => item.category);
      
      setFilteredCategories([...new Set(filteredCats)]);
      if(newBrand !== "MULTI")
      {
        setDashboardData(null);
        const dashboardResponse = await getDashboardSummary(activeToken, newBrand, 'ALL CATEGORIES');
        dashboardDataRef.current = dashboardResponse.sort((a, b) => b.ordinal - a.ordinal);
        setDashboardData(dashboardResponse);
        // Update filtered data based on the new brand
      }
      
       
      setIsLoading(false);
      
    };
  
    const handleDashboardCategoryChange = async (newCategory) => {
      setIsLoading(true);
      setDashboardData(null);
      setSelectedCategory(newCategory);
      const dashboardResponse = await getDashboardSummary(activeToken, selectedBrand, newCategory);
      dashboardDataRef.current = dashboardResponse.sort((a, b) => b.ordinal - a.ordinal);
      setDashboardData(dashboardResponse);
      // Update filtered data based on the new brand
      setIsLoading(false);
 
    };

    const getUniqueBrandsAndCategories = async (token, rootNode) => {
      try {
          const request = { token: token, treeNode: rootNode?.name };
          const response = await axios.post(`${API_URL}/salesgoals/brandscategories`, request);
          return response.data;
      } catch (error) {
          console.error('Error fetching brands and categories:', error);
      return [];
      }
  };

 

    async function getSalesGoalsSummary() {
       const response = await axios.get(`${API_URL}/salesgoals/summary?token=${activeToken}&treeNode=${selectedNode.name}&treeNodeName=${selectedNode.description}&salesGoal=${selectedSalesGoalID}`)
       return response.data;
      
    }

    async function getCustomerTree(token) {
      const response = await axios.get(`${API_URL}/salesgoals/tree?token=${token}`);
      return response.data;
      
  }

    useEffect(() => {
        if(selectedNode){
          setViewDashboard(false);
          setIsLoading(true);
          
          getSalesGoalsSummary()
          .then((response) => {
              setSalesDetails(response.details);
              setSalesSummary(response.summary);
              debugger;
              setRole(response.details[0]?.roleName);
          })
          .catch(error => {
              console.error('Error fetching data:', error);
          })
          .finally(stuff =>
            setIsLoading(false)
          );
        }
           
    }, [selectedNode,selectedSalesGoalID]);


    const handleNodeClick = ( salesGoalID, node) => {
      console.log("selectedNode", node);
      console.log("selected sg", salesGoalID);
      setSalesDetails(null);
      setSalesSummary(null);
        setSelectedNode(node);
        setSelectedSalesGoalID(salesGoalID);
        setViewDashboard(false);
        

    };


    function findNodeByName(node, nodeDesc) {
      if (node.description === nodeDesc) {
        return node;
      }
    
      for (let child of node.children) {
        const result = findNodeByName(child, nodeDesc);
        if (result) {
          return result;
        }
      }
    
      return null; // Return null if the node is not found
    }

    const handleDashboardNodeClick = (nodeDesc) =>{
      const fullNode = findNodeByName(treeData.rootNode, nodeDesc);
      if (fullNode) {
        setSelectedNode(fullNode);
        setSelectedSalesGoalID(fullNode.salesGoalID);
        
      } else {
        console.log("Node not found!");
      }


    }

    // Toggle minimize/expand manually
    const toggleMinimize = () => {
      setIsLeftNavMinimized(prevState => !prevState);
    };

      const handleGoalsRefresh = () =>{
        setIsLoading(true);
        getSalesGoalsSummary()
            .then((response) => {
                setSalesDetails(response.details);
                setSalesSummary(response.summary);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
          })
          .finally(stuff =>
            setIsLoading(false)
          );
    }

    
    const handleTreeRefresh = (node) =>{
        getCustomerTree(activeToken).then(response =>
        {
          setTreeData(response);
          setSelectedNode(node)
        })
        .catch(err => console.log(err));
    }

    return (
        <>
        {/* <div className='topBanner'>
            <img src="https://images.salsify.com/image/upload/s--UfN66dX_--/lcjysm1wbbdbjjlez449"  />
        </div> */}
        <div className='pageContainer'>
             <div className='headerContainer'>
             { !viewDashboard && role !== roleLevels.Level1 && (
                   <Button onClick={() => setViewDashboard(!viewDashboard)}> Back to Summary Dashboard</Button>
                )}
                {/* <h1>Sales Goals Workbook</h1> */}
               
                 
             </div>
             
            <div className="interiorContainer">
              {
                treeData && (      
                  <div
                  className="leftContainer"
                  style={{
                    width: isLeftNavMinimized ? '50px' : '350px', // Adjust width based on state
                    transition: 'width 0.6s ease', // Smooth transition
                  }}
                >
                  <div className="burger-icon-container">
                    <button onClick={toggleMinimize} className="burger-icon">&#9776;</button>
                  </div>
                  <div className="content" style={{ display: isLeftNavMinimized ? 'none' : 'block' }}>
                    <React.Fragment>
                      <CustomerTreeLegend treeData={treeData.rootNode} />
                      <div className="customerTree">
                        <CustomerTree nodes={treeData} onNodeClick={handleNodeClick} selectedNode={selectedNode} />
                       </div>
                      
                    </React.Fragment>
                </div>
             </div>
              )
            }
            <div
                className="rightContainer"
                style={{
                  width: isLeftNavMinimized ? 'calc(100% - 50px)' : 'calc(100% - 350px)', // Adjust right container width dynamically
                  transition: 'width 0.6s ease', // Smooth transition
                }}
              >
                  {viewDashboard && (dashboardData || isLoading) && (
                     <div className='summaryTable'>
                        <Dashboard role={role} 
                         dashboardData={dashboardData}
                         distinctBrands={distinctBrands}
                         filteredCategories={filteredCategories}
                         selectedBrand={selectedBrand}
                         selectedCategory={selectedCategory}
                         handleBrandChange={handleDashboardBrandChange}
                         handleCategoryChange={handleDashboardCategoryChange}
                         isLoading={isLoading}
                         
                         selectChannelNode={handleDashboardNodeClick} />
                      </div>
                  )}

                  {
                    !viewDashboard && selectedNode && (
                      <React.Fragment>
                        {isLoading && !salesDetails && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress size="30px" />
                          </div>
                        )}
                        {!isLoading && salesDetails && (
                          <React.Fragment>
                            <div className='charts'>
                              <ConsoleLog status={salesDetails[0].status} />
                              <Charts summary={salesSummary} salesGoalID={selectedSalesGoalID} status={salesDetails[0].status} />
                            </div>
                            <div className='summaryTable'>
                              <Goals
                                token={activeToken}
                                goalsData={salesDetails}
                                treeNode={selectedNode}
                                salesGoalID={selectedSalesGoalID}
                                isLoading={isLoading}
                                refresh={handleGoalsRefresh}
                                refreshTree={handleTreeRefresh}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )
                  }

                  {
                    !activeToken && (
                      <p>Loading...</p>
                    )
                    
                  }
              </div>
            </div>
        </div>
        
        </>
        

 
       
     
    );
}


const CustomerTreeLegend = ({treeData}) =>{
   if(treeData && treeData.children.length > 0  )
      return (


          <div className="legend">
              <div className="legendItem lockLevel1">Level 1</div>
              <div className="legendItem lockLevel2">Level 2</div>
              <div className="legendItem lockLevel3">Level 3</div>
              <div className="legendItem lockFinal">Final</div>
              <div className="clear" />
        </div>
      );
      else
      return (<></>);
}
export default Home;